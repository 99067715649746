import React from "react";
import { useLocation } from "react-router-dom";
const Eventtype = ()=>{
    const location = useLocation();
    const { name, description, image } = location.state || {}; 
    console.log(image,description,name);
    return <div>
    <div className="flex justify-center xs:max-md:flex-col items-center p-4 bg-slate-200 hover:bg-yellow-100 space-x-16">
  <div className="flex flex-col items-center mb-36 mr-20 xs:max-md:order-1">
    <div className="text-4xl text-center font-bold hover:text-fuchsia-400">
    <h2 >{name}</h2>
    <h2 className="mt-8 items-center text-2xl text-center font-extrabold text-fuchsia-400 mr-14">
     <a href="https://www.google.com/maps/dir//lclc/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4b5766356907ba4b:0xe2c1747a55fa3d9e?sa=X&ved=1t:3061&ictx=111"  target="_blank" > Bridgewater,Canada </a>
    </h2>
   </div>
   <p className=" ml-2 mt-8 text-left font-semibold">{description}</p> 
   <button className=" p-1 m-1 mt-6 mr-10 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-10 border border-blue-500 hover:border-transparent rounded">Register</button>
  </div>
  <div className="xs:max-md:order-2">
    <img class="w-[80vh] h-[64vh] object-cover rounded-lg shadow-md xs:max-md:object-fill" src={image} alt="profile1" />
    </div>
  </div>
  <div className='grid grid-cols-2 gap-3 px-24 py-4 h-full'>
            <div className='flex flex-row  items-center w-[90vh] h-[26vh] border border-2 border-blue-400 bg-white shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                <div className='text-lg p-4 font-semibold text-fuchsia-500'><b>8:30 AM - 9:00 AM: REGISTRATION AND NETWORKING </b></div>
                    <p className=' p-2 mb-4 text-left '>Start your day by checking in at the registration desk and take the opportunity to network with fellow attendees, speakers, and industry professionals.</p>
                </div>
            </div>
           <div className='flex flex-row w-full h-[26vh] border border-2  bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/register1.jpg" alt="tech" className="w-full h-full rounded-l-md object-cover"/>
            </div>
            <div className='flex flex-row items-center w-[90vh] h-[26vh] border border-2 border-orange-400 bg-white  mb-4 shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                <div className='text-lg p-4 font-semibold text-fuchsia-500'><b>9:00 AM - 9:15 AM: OPENING REMARKS </b></div>
                    <p className='p-4 text-justify'>Get inspired as our event host welcomes you to "Inspire & Innovate: A Journey of Possibilities" and sets the tone for a day filled with creativity, learning, and innovation.</p>
                </div>
            </div>
            <div className='flex flex-row w-full h-[26vh] border border-2 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/register2.jpg" alt="tech" className="w-full h-full rounded-l-md object-cover"/>
            </div>
            <div className='flex flex-row items-center w-full h-[26vh] border border-2 border-green-400 bg-white  mb-4 shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                <div className='text-lg p-4 font-semibold text-fuchsia-500'><b> 9:15 AM - 10:15 AM: KEYNOTE ADDRESS BY DR. EMMA JOHNSON </b></div>
                    <p className='p-4 text-justify'>Be captivated by Dr. Emma Johnson's thought-provoking keynote address on the power of innovation, unlocking your creative potential, and embracing a mindset of possibility.</p>
                </div>
            </div>
            <div className='flex flex-row w-full h-[26vh] border border-2  bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/register3.jpg" alt="tech" className="w-full h-full rounded-l-md object-cover"/>
            </div>
            <div className='flex flex-row  items-center w-[90vh] h-[26vh] border border-2 border-blue-400 bg-white shadow-md rounded-md'>
                <div className='flex flex-col items-center justify-center text-center w-full'>
                <div className='text-lg p-4 font-semibold text-fuchsia-500'><b> 10:15 AM - 10:30 AM: REFRESHMENT BREAK </b></div>
                    <p className='p-4 text-justify'>Take a short break to recharge and network with fellow attendees.</p>
                </div>
            </div>
            <div className='flex flex-row w-full h-[26vh] border border-2 border-purple-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/register4.jpg" alt="tech" className="w-full h-full rounded-l-md object-cover"/>
            </div>
            {/* <div className='flex flex-row w-full h-[26vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                <div className='text-2xl p-2 font-semibold text-fuchsia-500'><b>10:30 AM - 11:30 AM: "ILLUMINATE YOUR PATH" BREAKOUT SESSIONS </b></div>
                    <p className='p-4 text-justify'> Technovation: Embracing Disruptive Technologies.<br></br>Entrepreneurship and the Art of Building Successful Ventures<br></br>Design Thinking: Fueling Innovation and Creativity<br></br>Leadership for Innovation: Inspiring and Empowering Teams.</p>
                </div>
            </div> */}
            <div className='flex flex-row items-center w-full h-[26vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
                <div className='flex flex-col items-center w-full'>
                <div className='text-lg p-4 font-semibold text-fuchsia-500'><b> 10:30 AM - 11:30 AM: "ILLUMINATE YOUR PATH" BREAKOUT SESSIONS </b></div>
                    <p className='p-4 text-justify'>1.Technovation: Embracing Disruptive Technologies.<br></br>2.Entrepreneurship and the Art of Building Successful Ventures</p>
                </div>
            </div>
            <div className='flex flex-row w-full h-[26vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/register6.jpg" alt="tech" className="w-full h-full rounded-l-md object-cover"/>
            </div>
            </div>
    </div>
}
export default Eventtype;