import './App.css';
import { Route,Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Body from './components/Body';
import Services from './components/Services';
import Dance from './components/Dance';
import Packages from './components/Packages';
import Navbar from './components/Navbar';
import About from './components/About';
import Community from './components/Community';
import Events from './components/Events';
import Eventtype from './components/eventtype';
import Nature from './components/Nature';
import Blog from './components/Blog';
import Sports from './components/Sports';

function App() {
 
  return (
    <><Navbar/>
    <div>
      <Routes> 
      <Route path="/"  element= {<Body/>}/>
      <Route path="/about"  element={<About/>}/>
      <Route path="/services"  element={<Services/>}/>
      <Route path="/packages"  element={<Packages/>}/>
      <Route path="/community"  element={<Community/>}/>
      <Route path="/events"  element={<Events/>}/>
      <Route path="/dance"  element={<Dance/>}/>
      <Route path="/event-detail" element={<Eventtype/>} />
      <Route path="/nature" element={<Nature/>} />
      <Route path="/blogs" element={<Blog/>} />
      <Route path="/sports" element={<Sports/>} />
      {/* <Route path="/calendar" element={<Calendarnew events={events}/>}></Route> */}
    </Routes>
    <Footer/>
    </div>
    </>
  );
}

export default App;
