import React from "react";
import Calendarnew from "./Calendar";

const Dance = () => {
    const events = [
        { title: 'Bollywood', date: '2024-04-01' },
        { title: 'Bhangra', date: '2024-04-20' },
        { title: 'Bollywood', date: '2024-04-14' },
        { title: 'Bhangra', date: '2024-04-20' },
        { title: 'Bollywood', date: '2024-04-30' },
        { title: 'Bhangra', date: '2024-04-2' }
      ];

    return <div>
        <div className="grid grid-cols-1 place-items-center max-w-full mx-auto px-4 py-6 bg-white overflow-hidden" >
            <h1 className="text-6xl font-semibold mb-2 ">WHAT WE OFFER</h1>
        </div>
        <div className='grid grid-cols-3 gap-0 px-0 py-0 h-full '>
            <div className='w-full h-[65vh]  bg-blue-300 shadow-md rounded-md'>
            <img src="assets/bollywood2.jpg" alt="Image 1" className="w-full h-full object-cover"/>
            </div>
            <div className='w-full h-[65vh] bg-white shadow-md rounded-md'>
                <div className='flex flex-col justify-center items-center h-full'>
                    <div className='text-4xl font-medium leading-tight py-2 '><b>Indian Traditional </b></div>
                 {/* <button type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 rounded-md  px-10 py-3 text-center">Start Now</button> */}
                </div>
            </div>
            <div className='w-full h-44 bg-blue-300  shadow-md rounded-md'>
            <div className='w-full h-[65vh] bg-blue-300 shadow-md rounded-md'>
            <img src="assets/bhangra1.jpg" alt="Image 1" className="w-full h-full object-cover"/>
            </div>
            </div>
        </div>
        <div className='grid grid-cols-3 gap-0 px-0 py-0 h-full'>
        <div className='w-full h-[65vh] bg-white   shadow-md rounded-md'>
                <div className='flex flex-col justify-center items-center h-full'>
                    <div className='text-4xl font-medium leading-tight py-2 '><b>Bollywood </b></div>
                 {/* <button type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 rounded-md  px-10 py-3 text-center">Start Now</button> */}
                </div>
            </div>
            <div className='w-full  h-[65vh] bg-white shadow-md rounded-md'>
            <img src="assets/bharatnatyam1.jpg" alt="Image 1" className="w-full h-full object-cover"/>
            </div>
            <div className='w-full h-[65vh] bg-white shadow-md rounded-md'>
                <div className='flex flex-col justify-center items-center h-full'>
                    <div className='text-4xl font-medium leading-tight py-2 '><b>Bhangra </b></div>
                 {/* <button type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 rounded-md  px-10 py-3 text-center">Start Now</button> */}
                </div>
            </div>
        </div>
        <br></br>
        <h1 className="text-6xl font-serif mb-2 text-center">Program Schedule</h1>
        <Calendarnew events={events}/>
    </div>
};
export default Dance;
