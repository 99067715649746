import React , {useRef,useState, useEffect} from 'react';
import { Link } from 'react-router-dom';


const DropdownMenu = ({closeDropdown}) => {

  return (
    <div  className={'absolute bg-white rounded shadow-md mt-2 w-32'}>
      <Link onClick={closeDropdown} to="/events" className="block px-4 py-2 text-gray-800  hover:text-purple-600">Events</Link>
      <Link onClick={closeDropdown} to="/nature" className="block px-4 py-2 text-gray-800  hover:text-purple-600">Nature</Link>
      <Link onClick={closeDropdown} to="/sports" className="block px-4 py-2 text-gray-800 hover:text-purple-600">Sports </Link>
      <Link onClick={closeDropdown} to="/dance" className="block px-4 py-2 text-gray-800  hover:text-purple-600">Dance</Link>
      <Link onClick={closeDropdown} to="/community" className="block px-4 py-2 text-gray-800  hover:text-purple-600">Community</Link>
     </div>
  );
};

export default DropdownMenu;
