import React from "react";
import Blog from "./Blog";
import Eventtype from "./eventtype";

const Community = ()=>{
  return <div>  
<Blog/>
{/* <Eventtype/> */}
</div>
};
export default Community;