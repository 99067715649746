import React from "react";
import backgroundImage from "../img/2.png"
import Blog from "./Blog";
import {Link} from 'react-router-dom';

const Services = () => {

    return <div >
        <div className="items-center justify-center w-full h-screen bg-cover bg-center xs:h-[20vh]  bg-no-repeat monitor:flex  md:h-[45vh]  sm:h-[32vh] lg:h-[65vh]  xl:h-[85vh] xl:object-contain 2xl:h-[80vh]  laptop:h-[110vh] laptop:object-contain  monitor:items-center monitor:justify-center monitor:h-[100vh] monitor:bg-cover monitor:bg-center"
    style={{
      backgroundImage: `url(${backgroundImage})`,
    }}>
        </div>
        <div className="grid grid-cols-1 place-items-center max-w-full mx-auto px-4 py-6 bg-white overflow-hidden" >
            <h1 className="xs:text-xl lg:text-6xl font-semibold mb-2">WHAT WE DO</h1>
            {/* <p className="text-gray-600">At Upstart, we pride ourselves on offering exceptional customer experiences for every client that walks through our doors</p> */}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 laptop:grid-cols-3 monitor:grid-cols-3 gap-4 px-24 py-3 h-full'>
            <div className='w-full sm:w-auto xs:w-[20vh] md:h-44  lg:h-44 xl:h-44 2xl:h-44 monitor:h-44 laptop:h-44 border border-1 border-black bg-blue-200 shadow-md rounded-md '>
                <div className='flex flex-col items-center '>
                    <svg class="p-1 m-1 w-16 mt-4 h-12 text-gray-800 dark:text-black  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd" />
                    </svg>
                    <div className='text-3xl font-medium leading-tight xs:text-center'><Link  to="/events"><b>Local Events </b></Link></div>
                    <p className='px-3 text-justify '>Events happening in Nova Scotia.</p>
                </div>
            </div>
            <div className='w-full sm:w-auto xs:w-[20vh] md:h-44  monitor:h-44 border border-1 border-black bg-blue-200 shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                    <svg class="p-1 mt-2 w-16 h-14 text-gray-800 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M13 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V3ZM6.343 4.929A1 1 0 0 0 4.93 6.343l1.414 1.414a1 1 0 0 0 1.414-1.414L6.343 4.929Zm12.728 1.414a1 1 0 0 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 1.414 1.414l1.414-1.414ZM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-9 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H3Zm16 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2ZM7.757 17.657a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414Zm9.9-1.414a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM13 19a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z" clip-rule="evenodd" />
                    </svg>
                    <div className='text-3xl font-medium leading-tight xs:text-center'><Link to="/nature"><b>Embrace Nature</b></Link></div>
                    <p className='px-3 text-center'>Experience the thrill of nature with hiking, biking, and trail walking, offering a refreshing escape into the outdoors.</p>
                </div>
            </div>
            <div className='w-full sm:w-auto  xs:w-[20vh] md:h-44 monitor:h-44 border border-1 border-black bg-blue-200 shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                    {/* <svg class="p-1 m-1 mt-3 w-16 h-12 text-gray-800 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 21v-9m3-4H7.5a2.5 2.5 0 1 1 0-5c1.5 0 2.875 1.25 3.875 2.5M14 21v-9m-9 0h14v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-8ZM4 8h16a1 1 0 0 1 1 1v3H3V9a1 1 0 0 1 1-1Zm12.155-5c-3 0-5.5 5-5.5 5h5.5a2.5 2.5 0 0 0 0-5Z" />
                    </svg> */}
                    <svg class="mt-3 w-16 h-12 text-gray-800 dark:text-black"xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="black" d="m14.34 17.77l1.41-1.41L20 20.58L18.56 22zM18.5 2A3.5 3.5 0 0 1 22 5.5A3.5 3.5 0 0 1 18.5 9A3.5 3.5 0 0 1 15 5.5A3.5 3.5 0 0 1 18.5 2M2.24 7.11l2.83-2.83a1.02 1.02 0 0 1 1.43 0l8.47 8.49c.39.39.39 1.02 0 1.41L12.14 17a.99.99 0 0 1-1.42 0L2.24 8.53c-.39-.4-.39-1.03 0-1.42"/></svg>
                    <div className='text-3xl font-medium leading-tight xs:text-center'><Link  to="/sports"><b>Sport Activities</b> </Link></div>
                    <p className='px-3 text-center'>Discover a world of sports with activities like badminton, chess, cricket, and more, offering excitement and competition for all ages.</p>
                </div>
            </div>
        </div>
        <div className='grid grid-cols-1 xs:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 monitor:grid-cols-2 laptop:grid-cols-2 gap-4 px-24 py-2'>
            <div className='w-full sm:w-auto  xs:w-[20vh] md:h-44 lg:w-[45vh] lg:h-48 xl:h-48 2xl:h-48 laptop:h-56 monitor:h-56 border border-1 border-black bg-blue-200  shadow-md rounded-md xs:justify-self-start  sm:justify-self-end '>
                <div className='flex flex-col items-center content-center xs:text-center'>
                    <svg class="p-1  m-1 w-16 h-12 text-gray-800 dark:text-black  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12 5a7 7 0 0 0-7 7v1.17c.313-.11.65-.17 1-.17h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H6a3 3 0 0 1-3-3v-6a9 9 0 0 1 18 0v6a3 3 0 0 1-3 3h-2a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h2c.35 0 .687.06 1 .17V12a7 7 0 0 0-7-7Z" clip-rule="evenodd" />
                    </svg>
                    <div className='text-3xl font-medium leading-tight'><Link to="/dance"><b>Dance Academy</b></Link></div>
                    <p className='px-3 text-center'>Experience the energy of Bollywood, the vibrance of Bhangra, and the grace of traditional dance at our Dance Academy. Join our diverse classes and let the music move you.</p>
                </div>
            </div>
            <div className='w-full sm:w-auto  xs:w-[20vh]  md:h-44 lg:w-[45vh] lg:h-48 monitor:h-56 laptop:h-56 border border-1 border-black bg-blue-200  shadow-md rounded-md justify-self-start'>
                <div className='flex flex-col items-center'>
                    <svg class="p-1 m-1 w-16 h-12 text-gray-800 dark:text-black " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" clip-rule="evenodd" />
                    </svg>
                    <div className='text-2xl font-medium leading-tight xs:text-center'><Link to="/blogs"><b>Wellness Blogs</b></Link></div>
                    <p className='px-3 text-center p-1'>Stay connected with our Community Blog! Find local news, upcoming events, and inspiring stories from your neighborhood. Join us to discover what makes our community unique.</p>
                </div>
            </div>
            {/* <div className='w-full h-44 border border-2 border-black bg-white shadow-md rounded-md'>
                <div className='flex flex-col items-center'>
                    <svg class="p-1 m-1 w-16 h-12 text-gray-800 dark:text-black hover:bg-violet-600 active:bg-violet-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z" />
                    </svg>
                    <div className='text-2xl font-medium leading-tight'><b>Friendly Environment</b></div>
                    <p className='px-3 text-justify'>The use of professional classes in web development refers to employing standardized, well-documented CSS classes provided by frameworks like Tailwind CSS or Bootstrap</p>
                </div>
            </div> */}
        </div>
        <br></br>
        {/* <h1 className="text-5xl font-semibold mb-2 text-center">OUR BLOGS</h1>
        <Blog /> */}
    </div>
};
export default Services;
