import React from 'react';
import Partner from './Partner';
import Home from './Home'
import Blog from './Blog';
import Video from './Video';
import Packages from './Packages';
import Services from './Services';
import Testimonials from './Testimonials';
import Nature from './Nature';

function Body() {
  return (
    <div>
        <Home/>
    <div className="container  max-w-full mx-auto bg-white overflow-hidden" >
     
      <div className='flex h-[80vh]  justify-start items-center overflow-x-auto no-scrollbar  bg-black'>
      <div class="flex animate-scrolling justify-start items-center">
        {/* <div class="flex-none bg-gray-200 p-0 m-2 hover:shadow-md"> */}
        <div class='flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8 '>
          <img src="assets/dance12.avif" alt="Image 1" className="w-[40vh] h-25 object-cover"/>
        </div>
        <div class="flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8 ">
          <img src="assets/chess.jpg" alt="Image 2" className= "w-[55vh] h-25 object-cover"/>
          <div class="mt-0">
            <p className='bg-black text-neutral-300'><b> </b></p>
          </div>
        </div>
        <div class="flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8">
          <img src="assets/cricket1.jpg" alt="Image 3" className= "w-[65vh] h-25 object-cover"/>
          <div class="mt-0 text-neutral-50">
            <p></p>
          </div>
        </div>
        <div class="flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8">
          <img src="assets/riverevent.jpg" alt="Image 4" className= "w-[60vh] h-25 object-cover"/>
        </div>
        <div class="flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8">
          <img src="assets/icehockey1.png" alt="Image 4" className="w-[65vh] h-25 object-cover"/>
        </div>
       
        <div class="flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8">
          <img src="assets/hiking2.webp" alt="Image 4" className="w-[60vh] h-25 object-cover"/>
        </div>
        <div class="flex-none justify-center relative bg-gray rounded-lg overflow-hidden shadow-md p-1 m-8">
          <img src="assets/eventnew.jpg" alt="Image 4" className="w-[60vh] h-25 object-cover"/>
        </div>
        </div>
      </div>
{/* 
      <Video /> */}
      <Services/>
      <br></br>
      {/* <h1 className="text-5xl font-semibold mb-2 text-center">OUR BLOGS</h1> */}
      <br></br>
      {/* <Services/> */}
       {/* <Partner /> */}
      {/* <Packages/> */}
      {/* <Testimonials/> */}
      {/* <Nature/> */}
    </div>
    </div>
  );
}

export default Body;
