
import React , {useRef,useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
     
    };
    

     document.addEventListener("mousedown", handleClickOutside);
     return () => {
       // Unbind the event listener on clean up
       document.removeEventListener("mousedown", handleClickOutside);
   };
  }, [isDropdownOpen]);
  const toggleDropdown = () => {
    console.log("Inside dropdown")
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div>
        {/* <div className='flex justify-center bg-white '>
        <img src="assets/longtext.png" alt="tech" className="w-40 h-10  object-contain"/>
      </div> */}
    <div className='flex justify-between items-center px-2 bg-white '>
      <nav  className='flex flex-grow py-1 shadow-lg'>
        <div className='monitor:w-[12vh] content-start xs:w-[6vh] '><img src="assets/HWA.png" /></div>
        <ul  className="flex space-x-4 flex-grow justify-center items-center ">
          <li> <Link to="/" className='hover:text-purple-600 xs:text-md lg:text-sm laptop:text-lg'>Home</Link> </li>
          <li><Link to="/about" className="hover:text-purple-600 xs:text-md lg:text-sm laptop:text-lg">About</Link></li>
          <li ref={dropdownRef} ><Link to="/" className="hover:text-purple-600 lg:text-sm xs:text-md laptop:text-lg" onClick={toggleDropdown}>Services</Link >
            {isDropdownOpen &&  <DropdownMenu closeDropdown={closeDropdown} /> }</li>
          <li><Link to="/packages" className="hover:text-purple-600 xs:text-md lg:text-sm laptop:text-lg">Packages</Link ></li>
        </ul>
      </nav>
    </div>
    </div>
  );
}

export default Navbar;


