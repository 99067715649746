import React from "react";
import { useNavigate } from 'react-router-dom';
import { Map, Marker } from "pigeon-maps";
const Sports= ()=>{

  return <div>  
<div className="flex justify-center xs:max-md:gap-4  xs:max-md:flex-col items-center p-4 bg-slate-200 hover:bg-yellow-100 space-x-16">
<div className="flex flex-col items-center mb-36 mr-20 xs:max-md:order-2">
    <div className="text-4xl  xs:max-md:ml-16 text-center font-bold hover:text-fuchsia-400">
    <h2 >Beyond the Scoreboard:  </h2>
    <h2 >Celebrating the Power of Sports</h2>
    <h2 className="mt-8 items-center text-2xl text-center font-extrabold text-fuchsia-400 mr-14">
     <a href="https://www.google.com/maps/dir//lclc/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4b5766356907ba4b:0xe2c1747a55fa3d9e?sa=X&ved=1t:3061&ictx=111"  target="_blank" > Bridgewater,Canada </a>
    </h2>
   </div>
   <p className="xs:max-md:ml-16 ml-2 mt-8 text-left font-semibold">Join us for an extraordinary game that will ignite your<br></br> imagination and inspire you to reach new heights.</p> 
   <button className=" p-1 m-1 mt-6 mr-10 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-10 border border-blue-500 hover:border-transparent rounded">Watch</button>
  </div>
  <div className="xs:max-md:order-1 ">
    <img class="xs:max-md:object-fill xs:max-md:mr-3 xs:max-md:justify-start w-[80vh] h-[64vh] object-cover rounded-lg shadow-md" src="assets/sports1.jpg" alt="profile1" />
    </div>
  </div>
  <br></br>
  <div className="grid grid-cols-1 place-items-center max-w-full mx-auto px-4 py-2 bg-white overflow-hidden" >
            <h1 className="text-6xl font-semibold mb-2 ">Sports</h1>
        </div>
        <div className='grid xl:grid-cols-2 xs:max-lg:grid-cols-1 monitor:grid-cols-2 gap-4 px-24 py-6 h-full'>
            <div className='flex md:max-monitor:flex-row w-full sm:h-[32vh]  md:h-[26vh] xs:max-md:flex-col xs:h-[45vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/cricket2.jpg" alt="tech" className="w-48 h-full rounded-l-md object-cover xs:max-md:w-full xs:max-md:h-[10vh]"/>
                <div className='flex flex-col items-center'>
                <div className='sm:text-2xl xs:text-sm xs:text-center xs:max-md:p-2 p-6 font-semibold text-fuchsia-500'><b>Every Sunday</b></div>
                    <div className='sm:text-2xl xs:text-sm xs:text-center font-extrabold leading-tight' > <b >Cricket Match</b></div>
                    <p className='text-2xl xs:text-sm xs:text-center xs:p-1 md:p-4 sm:p-2 sm:text-justify'>The use of professional classes in web development refers to employing standardized, well-documented CSS classes provided by frameworks like Tailwind CSS or Bootstrap</p>
                </div>
            </div>
            <div className='flex md:max-monitor:flex-row w-full sm:h-[32vh]  md:h-[26vh] xs:max-md:flex-col xs:h-[45vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/football.jpeg" alt="tech" className="w-48 h-full rounded-l-md object-cover xs:max-md:w-full xs:max-md:h-[10vh]"/>
                <div className='flex flex-col items-center'>
                <div className='sm:text-2xl xs:text-sm xs:text-center xs:max-md:p-2 p-6 font-semibold text-fuchsia-500'><b>December 16-17,2023</b></div>
                    <div className='sm:text-2xl xs:text-sm xs:text-center font-extrabold leading-tight'><b >Football Game</b></div>
                    <p className='text-2xl xs:text-sm xs:text-center xs:p-1 md:p-4 sm:p-2 sm:text-justify'>It serves as a platform for tech enthusiasts, innovators, and industry leaders to come together.</p>
                </div>
            </div>
            <div className='flex md:max-monitor:flex-row w-full sm:h-[32vh]  md:h-[26vh] xs:max-md:flex-col xs:h-[45vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/chess3.webp" alt="tech" className="w-48 h-full rounded-l-md object-cover xs:max-md:w-full xs:max-md:h-[10vh]"/>
                <div className='flex flex-col items-center'>
                <div className='sm:text-2xl xs:text-sm xs:text-center xs:max-md:p-2 p-6 font-semibold text-fuchsia-500'><b>December 22-23,2023</b></div>
                    <div className='sm:text-2xl xs:text-sm xs:text-center font-extrabold leading-tight'><b >CHESS GAME AT LCLC</b></div>
                    <p className='text-2xl xs:text-sm xs:text-center xs:p-1 md:p-4 sm:p-2 sm:text-justify'>Join us for an extraordinary event that will ignite your imagination and inspire you to reach new heights.</p>
                </div>
            </div>
            <div className='flex md:max-monitor:flex-row w-full sm:h-[32vh]  md:h-[26vh] xs:max-md:flex-col xs:h-[45vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/basketball.webp" alt="tech" className="w-48 h-full rounded-l-md object-cover xs:max-md:w-full xs:max-md:h-[10vh]"/>
                <div className='flex flex-col items-center'>
                <div className='sm:text-2xl xs:text-sm xs:text-center xs:max-md:p-2 p-6 font-semibold text-fuchsia-500'><b>December 25-26,2023</b></div>
                    <div className='text-2xl sm:text-2xl xs:text-sm xs:text-center font-extrabold leading-tight text-center'><b >Basketball Gameplay</b></div>
                    <p className='text-2xl xs:text-sm xs:text-center xs:p-1 md:p-4 sm:p-2 sm:text-justify'>This immersive experience is curated to immerse you in the diverse cultures and landscapes that our planet has to offer.</p>
                </div>
            </div>
            <div className='flex md:max-monitor:flex-row w-full sm:h-[32vh]  md:h-[26vh] xs:max-md:flex-col xs:h-[45vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/hockey.jpg" alt="tech" className="w-48 h-full rounded-l-md object-cover xs:max-md:w-full xs:max-md:h-[10vh]"/>
                <div className='flex flex-col items-center'>
                <div className='sm:text-2xl xs:text-sm xs:text-center xs:max-md:p-2 p-6 font-semibold text-fuchsia-500'><b>January 2-4,2024</b></div>
                    <div className='text-2xl sm:text-2xl xs:text-sm xs:text-center font-extrabold leading-tight text-center'><b >ICE HOCKEY</b></div>
                    <p className='text-2xl xs:text-sm xs:text-center xs:p-1 md:p-4 sm:p-2 sm:text-justify'>Join us for an extraordinary two-day journey into the realm of innovation at The Innovation Summit.</p>
                </div>
            </div>
            <div className='flex md:max-monitor:flex-row w-full sm:h-[32vh]  md:h-[26vh] xs:max-md:flex-col xs:h-[45vh] border border-2 border-blue-400 bg-white  mb-4 shadow-md rounded-md'>
               <img src="assets/tennis.avif" alt="tech" className="w-48 h-full rounded-l-md object-cover xs:max-md:w-full xs:max-md:h-[10vh]"/>
                <div className='flex flex-col items-center'>
                <div className='sm:text-2xl xs:text-sm xs:text-center xs:max-md:p-2 p-6 font-semibold text-fuchsia-500'><b>January 6-8,2024</b></div>
                    <div className='text-2xl fontext-2xl sm:text-2xl xs:text-sm xs:text-center font-extrabold leading-tight text-center'><b >TENNIS </b></div>
                    <p className='text-2xl xs:text-sm xs:text-center xs:p-1 md:p-4 sm:p-2 sm:text-justify'>This immersive expo is designed to captivate your imagination and inspire you with the possibilities that lie ahead.</p>
                </div>
            </div>
            </div>
         
     
        {/* <div className='grid grid-cols-2 gap-0 px-0 py-0  space-y-2 justify-center'>
        <div className="flex flex-col justify-center max-w-xs h-[55vh] bg-slate-100 ">
          image1
        </div>
        <div className="flex flex-col max-w-xs h-[55vh] bg-slate-100 ">
          image2
        </div>
        <div className="flex flex-col max-w-xs h-[55vh] bg-slate-100 ">
          image3
        </div>
        <div>
          image4
        </div>
        <div>
          image5
        </div>
        <div>
          image6
        </div>
        </div>    */}
        <Map height={300} defaultCenter={[44.3752, -64.5005]} defaultZoom={16}>
         <Marker width={50} anchor={[44.3850, -64.5005]} />
      </Map> 
</div>
};
export default Sports;


