
import React from "react";
import Blog from "./Blog";
import Events from "./Events";

const Packages = ()=>{
  return <div>  
<div className='flex flex-col items-center p-6'> <h1 className="text-5xl font-semibold mb-2">Our Packages</h1>
<p className="text-gray-600">At Upstart, we pride ourselves on offering exceptional customer experiences for every client that walks through our doors</p>
</div>
<div class="flex justify-center space-x-4 h-full p-4 mt-3 mb-6 bg-zinc-500">
<div class="flex flex-col max-w-xs h-[55vh] bg-slate-100 p-4 my-1 rounded-lg shadow-md items-center justify-between hover:bg-yellow-100">
  <h2 class="text-2xl font-semibold mb-2">General</h2>
  <p className='text-2xl font-semibold mb-2'>$10/month</p>
  <p>Over Cardio Pieces</p>
  <p class="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.</p>
  <button type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 rounded-md  px-10 py-3 text-center">Start Now</button>
</div>
<div class="flex flex-col max-w-xs h-[55vh] bg-slate-100 p-4 my-1 rounded-lg shadow-md items-center justify-between hover:bg-red-100">
  <h2 class="text-2xl font-semibold mb-2">Intermediate</h2>
  <p className='text-2xl font-semibold mb-2'>$10/month</p>
  <p>Over Cardio Pieces</p>
  <p class="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.</p>
  <button type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 rounded-md  px-10 py-3 text-center">Start Now</button>
</div>
<div class="flex flex-col max-w-xs h-[55vh] bg-slate-100 p-4 my-1 rounded-lg shadow-md items-center justify-between hover:bg-blue-200">
  <h2 class="text-2xl font-semibold mb-2">Professional</h2>
  <p className='text-2xl font-semibold mb-2'>$10/month</p>
  <p>Over Cardio Pieces</p>
  <p class="text-gray-700 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.</p>
  <button type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 rounded-md  px-10 py-3 text-center">Start Now</button>
</div>
</div>
</div>
};
export default Packages;