import React from "react";
import backgroundImage from "../img/hiking.jpeg"
const Nature = () =>{

 return <div>
         <div className="flex items-center justify-center h-[110vh] bg-cover bg-center"
    style={{
      backgroundImage: `url(${backgroundImage})`,
    }}>
 {/* Image on the left */}
 
        </div>
       <div className="flex flex-row"> <img
        src="assets/biking.jpg"
        alt="Left"
        className="w-[108vh] h-[48vh>] object-fill rounded-lg" // Left image
      />

      {/* Image on the right */}
      <img
        src="assets/running.jpg"
        alt="Right"
        className="w-[108vh] h-[48vh>] object-fill rounded-lg" // Right image
      />
      </div>
 </div>


}
export default  Nature;