import React from 'react';
import backgroundImage from "../img/1.png"; // Add your background image here


const Home = () => {
  return (
    <div
      className="items-center justify-center w-full h-screen bg-cover bg-center  bg-no-repeat monitor:flex xs:object-contain xs:h-[35vh]   sm:object-contain sm:h-[35vh] md:h-[50vh] md:object-contain lg:object-contain lg:h-[70vh] xl:object-contain xl:h-[85vh] 2xl:object-contain 2xl:h-[80vh] laptop:h-[120vh] laptop:object-contain monitor:object-contain  monitor:h-[120vh] monitor:bg-cover monitor:bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
    </div>
  );
};

export default Home;
