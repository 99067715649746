import React, { useState } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";

const Calendarnew = ({ events }) => {

    const handleDateClick = (arg) => {
        alert(arg.dateStr)
        
    }



    return(
     <div className="justify-center w-full h-96 overflow-auto bg-gray-200 ">  <FullCalendar  height={750} 
          aspectRatio={3}  plugins={[dayGridPlugin , interactionPlugin]}
            initialView="dayGridMonth"
            views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
            events={events}
            dateClick={handleDateClick}
            contentHeight={'auto'}
        />
        </div>
    );
    


}
export default Calendarnew;