// Div2Component.js
import React from 'react';
import backgroundImage from "../img/3.png"
const Blog = () => {
  return <div> <div className="flex items-center justify-center h-[110vh] bg-cover bg-center"
  style={{
    backgroundImage: `url(${backgroundImage})`,
  }}>

</div><div className='flex flex-col items-center'>
    <h1 className="text-5xl font-semibold mb-2 text-center">OUR BLOGS</h1>
  </div>
    <div className="flex justify-center">
      <div className="max-w-md bg-white shadow-md rounded-md overflow-hidden mx-4 mb-4">
        <img src="assets/community1.jpg" alt="Dance 1" className="w-full h-56 object-cover" />
        <div className="p-4">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb1qVsnJCpjLnZc-9QSZrbteh5saZ_gpPc2hfhanm76jYOOMfdKVh9UvNnfLyhlpMWiiU&usqp=CAU"
            className="w-12 rounded-full"
            alt="Avatar" />
          <h2 className="text-xl font-semibold mb-2">Rediscovering the Outdoors: Your Guide to Embracing Nature</h2>
          <p className="text-gray-600 p-1">Take a break from the daily grind and immerse yourself in nature. Discover the best hiking trails, scenic walks, and outdoor adventures that will reconnect you with the beauty of the world around you. Get ready to embrace nature like never before!</p>
          <button className=" p-1 m-1 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Read More
          </button>
        </div>
      </div>

      <div className="max-w-md bg-white shadow-md rounded-md overflow-hidden mx-4 mb-4">
        <img src="assets/community2.jpg" alt="Dance 2" className="w-full h-56 object-cover" />
        <div className="p-4">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb1qVsnJCpjLnZc-9QSZrbteh5saZ_gpPc2hfhanm76jYOOMfdKVh9UvNnfLyhlpMWiiU&usqp=CAU"
            className="w-12 rounded-full"
            alt="Avatar" />
          <h2 className="text-xl font-semibold mb-2">What's Happening in Your Neighborhood? Local Events and Activities</h2>
          <p className="text-gray-600 p-1">Stay in the loop with the latest local events in your area. From community gatherings to cultural festivals, find out what's happening near you. Whether you're looking for family-friendly fun or adult-oriented activities, we've got you covered.</p>
          <button className=" p-1 m-1 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Read More
          </button>
        </div>
      </div>
      <div className="max-w-md bg-white shadow-md rounded-md overflow-hidden mx-4 mb-4">
        <img src="assets/community2.jpg" alt="Dance 2" className="w-full h-56 object-cover" />
        <div className="p-4">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb1qVsnJCpjLnZc-9QSZrbteh5saZ_gpPc2hfhanm76jYOOMfdKVh9UvNnfLyhlpMWiiU&usqp=CAU"
            className="w-12 rounded-full"
            alt="Avatar" />
          <h2 className="text-xl font-semibold mb-2">Get Active! A Look at the Sports Scene in Your Community</h2>
          <p className="text-gray-600 p-1">Lace up your sneakers and get ready to play! Explore a variety of sports activities like badminton, chess, cricket, and more. Discover local clubs, tournaments, and friendly competitions where you can join in the fun and meet new people.</p>
          <button className=" p-1 m-1 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Read More
          </button>
        </div>
      </div>
    </div>
   
  </div>;
};

export default Blog;



