import React from "react";


const About= ()=> {
return <div >
  <section class="bg-gray-100">
    <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div class="max-w-lg">
                <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">About Us</h2>
                <p class="mt-4 text-gray-600 text-lg text-justify">Two passionate individuals with a shared vision of creating a vibrant community where people from all backgrounds come together. With diverse expertise in community-building and cultural engagement, they aim to foster connections, celebrate diversity, and build a space where everyone feels welcome. Join them as they work to bring people together, one meaningful connection at a time.</p>
               
            </div>
            <div class="mt-12 md:mt-0">
                <img src="assets/sunrise.jpg" alt="About Us Image" class="object-cover rounded-lg shadow-md"></img>
            </div>
        </div>
    </div>
</section>
</div>
};
export default About;